import { whenAvailable } from "@formkit/utils";
import type { FormNode } from "../types";

export function debounce(func: Function, wait: number) {
  let timeout: ReturnType<typeof setTimeout> | null = null;
  return function executedFunction(...args: any[]) {
    const later = () => {
      clearTimeout(timeout!);
      func(...args);
    };
    clearTimeout(timeout!);
    timeout = setTimeout(later, wait);
  };
}

export const insertPointClasses = [
  "absolute",
  "bg-fk-accent",
  "z-[1000]",
  "top-0",
  "left-[-999px]",
  "rounded-full",
  "duration-[5ms]",
  "before:block",
  'before:content-["Insert"]',
  "before:whitespace-nowrap",
  "before:block",
  "before:bg-fk-accent",
  "before:py-1",
  "before:px-2",
  "before:rounded-full",
  "before:text-xs",
  "before:absolute",
  "before:top-1/2",
  "before:left-1/2",
  "before:-translate-y-1/2",
  "before:-translate-x-1/2",
  "before:text-white",
  "before:text-xs",
];

export function findNthOccurrenceAndSlice(
  arr: Array<FormNode>,
  identifier: "page_break",
  n: number,
  excludeIdentifier = false
): Array<FormNode> {
  let occurrenceCount = 0;
  let startIndex = -1;

  // Find the nth occurrence
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].type === identifier) {
      occurrenceCount++;
      if (occurrenceCount === n) {
        startIndex = i;
        break;
      }
    }
  }

  // If nth occurrence not found, return an empty array
  if (startIndex === -1) {
    return [];
  }

  // Find the next occurrence
  let endIndex = arr.length;
  for (let i = startIndex + 1; i < arr.length; i++) {
    if (arr[i].type === identifier) {
      endIndex = i;

      break;
    }
  }

  // startIndex + 1 because we don't want the page_breaks
  return arr.slice(excludeIdentifier ? startIndex + 1 : startIndex, endIndex);
}

export function findAndReplace(
  arr: Array<FormNode>,
  identifier: "page_break",
  n: number,
  replacement: Array<FormNode>
): Array<FormNode> {
  let occurrenceCount = 0;
  let startIndex = -1;

  // Find the nth occurrence
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].type === identifier) {
      occurrenceCount++;
      if (occurrenceCount === n) {
        startIndex = i;
        break;
      }
    }
  }

  // If nth occurrence not found, return an empty array
  if (startIndex === -1) {
    return [];
  }

  // Find the next occurrence
  let endIndex = arr.length;
  for (let i = startIndex + 1; i < arr.length; i++) {
    if (arr[i].type === identifier) {
      endIndex = i;

      break;
    }
  }

  // startIndex + 1 because we don't want the page_breaks
  return [
    ...arr.slice(0, startIndex + 1),
    ...replacement,
    ...arr.slice(endIndex),
  ];
}

export function whenAllAvailable(
  ids: string[],
  callback: (...elements: HTMLElement[]) => void
) {
  const elements: Map<string, HTMLElement> = new Map();
  ids.forEach((id) =>
    whenAvailable(id, (el) => {
      elements.set(id, el as HTMLElement);
      if (elements.size === ids.length) {
        callback(...ids.map((id) => elements.get(id)!));
      }
    })
  );
}
